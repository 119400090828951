.pm-dropdown {
  // .MuiFormLabel-root {
  //   color: #a0988f !important;
  // }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e8e2dd !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #a0988f !important;
  }
  .MuiSelect-select {
    background-color: #ffffff !important;
  }
}
