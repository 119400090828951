.news-wrapper {
  flex-direction: column;
  background: linear-gradient(0.38deg, rgba(255, 250, 247, 0) 0%, #fff4eb 100%);

  .heading {
    text-align: center;
    color: #e7792b;
    font-family: Poppins;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 51px;
    padding: 80px 0px;
  }
  .news-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 1110px;
    justify-content: space-around;
    margin: 0 auto;
  }
  .news-content-container {
    min-height: 500px;
    width: 350px;
    margin-bottom: 50px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    background-color: #ffffff;

    .img-container {
      text-align: center;
      background-color: #fff4eb;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 185px;
    }
    img {
      max-height: 185px;
      max-width: 348px;
    }
    .content-div {
      padding: 25px 35px 20px 20px;
      max-width: 348px;
    }
    .title {
      color: #544837;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 28px;
    }
    .subtitle {
      margin-top: 10px;
      color: #2f281e;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      // display: -webkit-box;
      // -webkit-line-clamp: 6;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;

      a {
        color: #2f281e;

        &:active,
        &:hover {
          color: #ff9900 !important;
        }
      }
    }
  }
}
