.workflow-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.333333px;
  color: #aeb2b8;
  margin: 20px 0px 35px 0px;
}

.workflow_tabs {
  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-root {
    max-width: 200px;
    height: 60px;
    background: #e9e9e9;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #a0988f;

    &.Mui-selected {
      background-color: #e7792b !important;
      color: #ffffff !important;
    }
  }

  .MuiTabs-indicator {
    background-color: white;
  }

  .first_tab {
    margin-right: 15px;
  }

  .PrivateTabIndicator-root-1 {
    display: none;
  }
}

.workflow-square {
  text-align: center;
  //padding: 40px 0px;
}

.workflow-img-container {
  display: inline-block;
  // min-width: 675px;
}

.workflow-image-text {
  position: absolute;
  bottom: 125px;
  right: 135px;
  font-size: 12px;
}

.text-row {
  padding: 20px 0px;
  display: flex;
  justify-content: space-around;

  &.top-padding-0 {
    padding-top: 0px;
  }
}

.text-container {

  .mobile-title,
  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #46372e;
  }

  .title_two {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #46372e;
  }

  .title_three {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #46372e;
  }

  .title_four {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #46372e;
  }

  .title_five {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #46372e;
  }

  .sub-title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #aeb2b8;
    margin-top: 3px;
  }

  .sub-title-two {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #aeb2b8;
    margin-top: 3px;
  }

  .sub-title-three {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #aeb2b8;
    margin-top: 3px;
  }

  .sub-title-four {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #aeb2b8;
    margin-top: 3px;
  }

  .sub-title-five {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.333333px;
    color: #aeb2b8;
    margin-top: 3px;
  }
}

.workflow-circular {
  text-align: center;
  padding: 60px 0px;
}

.workflow-circle {
  .mobile-title {
    display: none;
  }
}

.workflow-container {
  flex-direction: row;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #bbb5af;
}

.workflow-image-overlap {
  position: relative;

  .text-content {
    color: #e87a2c;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    bottom: 40%;
    left: 38%;
    background-color: white;
  }

  .square_text_monthly {
    font-size: 14px;
    background-color: white;
    color: #eb792b;
    bottom: 46%;
    position: absolute;
    left: 41%;
  }

  .square_text_upto_days {
    font-size: 12px;
    background-color: white;
    color: #bbb5af;
    position: absolute;
    left: 41%;
    top: 6%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 812px) {
  .text-container {

    .mobile-title,
    .title {
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #46372e;
    }

    .sub-title {
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #aeb2b8;
      margin-top: 3px;
    }

    .title_two {
      position: absolute;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #46372e;
      bottom: 82%;
      left: 245px
    }

    .sub-title-two {
      position: absolute;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #aeb2b8;
      margin-top: 3px;
      bottom: 74%;
      left: 230px;
      width: 30%;

    }

    .title_three {
      position: absolute;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #46372e;
      left: 235px
    }

    .sub-title-three {
      position: absolute;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #aeb2b8;
      margin-top: 10px;
      left: 215px;
      width: 30%;
    }

    .title_four {
      position: absolute;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #46372e;
      left: 55px;
    }

    .sub-title-four {
      position: absolute;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #aeb2b8;
      margin-top: 10px;
      left: 45px;
      text-overflow: inherit;
      width: 30%;
    }

    .title_five {
      position: absolute;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #46372e;
      bottom: 82%;
      left: -25px;
    }

    .sub-title-five {
      position: absolute;
      display: inline;
      width: 40%;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.333333px;
      color: #aeb2b8;
      margin-top: 10px;
      bottom: 70%;
      left: -45px;
    }
  }

  .text-row {
    padding: 5px 0px;
    display: flex;
    justify-content: space-around;

    &.top-padding-0 {
      padding-top: 0px;
    }
  }

  .workflow-image-overlap {
    position: relative;

    .text-content {
      color: #e87a2c;
      font-weight: 500;
      font-size: 14px;
      position: relative;
      bottom: 40%;
      left: 38%;
      background-color: white;
    }

    .square_text_monthly {
      font-size: 14px;
      background-color: white;
      color: #eb792b;
      bottom: 86%;
      position: relative;
      left: 41%;
    }

    .square_text_upto_days {
      font-size: 12px;
      background-color: white;
      color: #bbb5af;
      position: absolute;
      left: 41%;
      top: 6%;
    }
  }

  .workflow-image-text {
    position: absolute;
    font-size: 8px;
    left: 92%;
    top: 62%;
    width: 20%;
  }
}