.textbox_icon {
  height: 16px;
  width: 13px;
}

.text-field {
  .MuiFormLabel-root {
    color: #a0988f !important;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e8e2dd !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #a0988f !important;
  }
}
