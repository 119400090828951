.demo-dashboard-container {
  max-width: 1110px;
  justify-content: center;
  margin: 40px auto 80px auto;

  .demo-content-wrapper {
    padding: 10px 0px 10px 0px;

    .title {
      color: #2f281e;
      font-family: Poppins;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 55px;
    }

    .discount-text {
      color: green;
    }

    .demo-title {
      color: #2f281e;
      font-family: Poppins;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 55px;
    }

    .offer-demo-title {
      color: #2f281e;
      font-family: Poppins;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 55px;
    }

    .subtitle {
      margin-top: 25px;
      color: #544837;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }

    .subheadline {
      margin-top: 10px;
      color: #544837;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;

    }

    .started-button {
      height: 53px;
      width: 175px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 50px 0px;
    }

    .schedule-button {
      height: 53px;
      width: 275px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 16px 0px;
    }

    .shop-button {
      height: 53px;
      width: 275px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 4px 0px 10px 0px;
    }

    .overview-points {
      list-style-image: url('../../PMAssets/PMImages/checkbox_selected.png');
      font-size: 18px;

    }

    .overview {
      color: #e7792b;
      margin-top: 20px;
      font-family: Poppins;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 55px;
    }

    .app-link {
      height: 60px;
      width: 180px;
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      background-color: #000000;
    }

    .app-links-container {
      display: flex;
    }

    .player-wrapper {
      margin-top: 10px;
      position: relative;
      padding-top: 56.25%;
      /* 720 / 1280 = 0.5625 */
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .demo-image-wrapper {
    .demo-dashboard-mission-image {
      height: auto;
      max-width: 550px;
      justify-content: left;

    }
  }
}

.player-container {
  margin-top: 20px;
  max-width: 768px;
  justify-content: center;
}

.slick-arrow {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .demo-dashboard-container {
    margin: 40px auto 50px auto;
    justify-content: center;

    .demo-content-wrapper {
      text-align: center;

      //padding-left: 10px;
      .demo-title {
        color: #2f281e;
        font-family: Poppins;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
      }

      .subtitle {
        margin-top: 25px;
        color: #544837;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
      }

      .offer-demo-title {
        color: #2f281e;
        font-family: Poppins;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
      }

      .subheadline {
        color: #544837;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
      }

      .overview-points {
        list-style-image: url('../../PMAssets/PMImages/checkbox_selected.png');
        font-size: 16px;

      }

      .schedule-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0px 10px 0px;
      }

      .shop-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 4px 0px 10px 0px;
      }

      .discount-text {
        color: green;
      }
    }

    .demo-image-wrapper {
      .demo-dashboard-mission-image {
        height: auto;
        max-width: 300px !important;
        justify-content: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
}

@media only screen and (min-width: 486px) and (max-width: 768px) {
  .demo-dashboard-container {
    margin: 40px auto 40px auto;
    justify-content: center;

    .demo-content-wrapper {
      text-align: center;

      //padding-left: 10px;
      .demo-title {
        color: #2f281e;
        font-family: Poppins;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
      }

      .subtitle {
        margin-top: 25px;
        color: #544837;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
      }

      .subheadline {
        color: #544837;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
      }

      .overview-points {
        list-style-image: url('../../PMAssets/PMImages/checkbox_selected.png');
        font-size: 16px;

      }

      .schedule-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0px 10px 0px;
      }

      .shop-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 15px 20px 30px 5px;
      }

      .discount-text {
        color: green;
      }
    }

    .demo-image-wrapper {
      .demo-dashboard-mission-image {
        height: auto;
        max-width: 300px !important;
        justify-content: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
}

@media only screen and (max-width: 486px) {
  .demo-dashboard-container {
    margin: 40px auto 40px auto;
    justify-content: center;

    .demo-content-wrapper {
      text-align: center;

      //padding-left: 10px;
      .demo-title {
        color: #2f281e;
        font-family: Poppins;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
      }

      .subtitle {
        margin-top: 25px;
        color: #544837;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
      }

      .subheadline {
        color: #544837;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
      }

      .schedule-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0px 10px 0px;
      }

      .shop-button {
        height: 53px;
        width: 275px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 15px 20px 30px 5px;
      }

      .discount-text {
        color: green;
      }
    }

    .demo-image-wrapper {
      .demo-dashboard-mission-image {
        height: auto;
        max-width: 300px !important;
        justify-content: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }

}

@media only screen and (min-width: 768px) and (max-width: 1110px) {

  .demo-dashboard-container .demo-content-wrapper {
    text-align: center;

    .demo-title {
      color: #2f281e;
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
    }

    .subtitle {
      margin-top: 25px;
      color: #544837;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }
  }

  .demo-dashboard-container .demo-image-wrapper {
    .demo-dashboard-mission-image {
      height: auto;
      max-width: 300px !important;
      justify-content: center;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

@media only screen and (max-width: 1110px) {
  .demo-dashboard-container {
    padding-left: 20px;
    padding-right: 20px;

    .demo-content-wrapper {
      text-align: center;

      .demo-title {
        color: #2f281e;
        font-family: Poppins;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
      }

      .subtitle {
        margin-top: 25px;
        color: #544837;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
      }
    }

    .demo-image-wrapper {
      .demo-dashboard-mission-image {
        height: auto;
        max-width: 320px !important;
        justify-content: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
}