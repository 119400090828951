.header-wrapper {
  box-sizing: border-box;
  padding: 20px 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;

  .logo {
    height: 40px;
    width: 124px;
    vertical-align: middle;
  }

  .header-menu-icon {
    display: none;
  }

  .header-nav {
    display: inline-flex;
    margin-right: 50px;

    a {
      margin: 0px 15px;
      color: #2f281e;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 40px;

      &.active {
        color: #e7792b !important;
      }

      &:hover {
        color: #e7792b !important;
      }
    }
  }
}

.header-button {
  height: 46px;
  width: 110px;
  border-radius: 4px !important;
  background-color: #e7792b !important;
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  display: inline-flex;
  margin-left: 15px;
  text-transform: capitalize;
}

.header-secondary-button {
  margin-right: 15px !important;
  height: 46px;
  width: 110px;
  border: 1px solid #bcd0e5 !important;
  border-radius: 4px !important;
  color: #173a56 !important;
  font-family: Poppins;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 25px;
  background-color: #ffffff !important;
}

.header-notification {
  display: inline-flex;
  margin: 0px 35px;
  align-items: center;
  position: relative;

  .MuiBadge-root {
    display: inline-block;
  }

  .MuiBadge-colorPrimary {
    background-color: #e7792b !important;
  }
}

.profile {
  display: inline-flex;
  position: relative;

  .userAvatar {
    text-transform: uppercase;
    border: 1px solid #ffffff;
    box-shadow: 0 2px 4px 0 rgba(188, 187, 187, 0.5);
    background-color: #e7792b;
    margin-right: 15px;

    img {
      width: auto !important;
    }
  }

  .profile-img {
    height: 47px;
    width: 47px;
    border: 1px solid #ffffff;
    box-shadow: 0 2px 4px 0 rgba(188, 187, 187, 0.5);
    border-radius: 25px;
    justify-content: center;
    display: inline-flex;
    font-size: 20px;
    font-weight: 600;
    background-color: #e7792b;
    color: #ffffff;
    line-height: 43px;
    margin-right: 18px;
  }

  .profile-arrow {
    width: 15px;
  }
}

.profile-ref {
  position: absolute;
  left: 0;
  bottom: -10px;
}

.child-list {
  padding-left: 25px !important;

  &.activeBaby {
    background-color: #eee;
  }
}

.notification-ref {
  position: absolute;
  left: -10px;
  bottom: -20px;
}

.notification-menu {
  min-width: 250px;
  height: 275px;
  overflow-y: auto;

  .loader-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.notification-wrapper {
  font-size: 14px;
  border-bottom: 2px solid #dedede;
  padding: 10px 20px;

  &.unread {
    border-bottom: 2px solid #fff;
    background-color: #e7792b40;
  }
}

.headerPhysician {
  min-width: 150px !important;

  .MuiOutlinedInput-input {
    padding: 13.5px 14px !important;
  }
}

.header-nav-menu {
  ul.MuiMenu-list {
    width: 300px;
  }

  .close-button-wrapper {
    position: absolute;
    right: 10px;

    button {
      margin: 0px;
    }
  }

  .link-wrapper {
    padding: 5px 20px;

    button {
      margin: 5px 0px;
    }
  }

  a {
    color: #2f281e;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 40px;

    &.active {
      color: #e7792b !important;
    }

    &:hover {
      color: #e7792b !important;
    }

    &.signin {
      color: #e7792b !important;
    }
  }

  button {
    margin: 10px;
  }

}

.header-menu-container {
  display: none;
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  //padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

// @media only screen and (min-width: 320px) and (max-width: 479px){}

// @media only screen and (min-width: 480px) and (max-width: 767px){}

// @media only screen and (min-width: 768px) and (max-width: 991px){}

@media only screen and (max-width: 900px) {
  .header-menu-container {
    display: block !important;
  }

  .header-nav-panel {
    display: none !important;
  }

  .header-menu-icon {
    display: block !important;
  }
}