.user-chat-container {
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background-color: white;
  width: 400px;
  border: 0px;
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  &.visible {
    overflow: initial;
    max-height: 500px !important;
    border: 1px solid #c3c3c3;
  }

  .chat-header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .chat-form {
    height: 100%;
    padding: 20px;
    background-color: #eee;
  }
  .chat-form-input {
    width: 100%;
    background-color: white;
  }
  .chat-wrapper {
    height: 300px;
    padding: 20px 10px 10px 10px;
    background-color: #eee;
    overflow: auto;
  }
  .delivery-details {
    min-width: 150px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    height: 10px;

    svg {
      width: 18px;
      height: 18px;
      color: blue;
    }
  }
  .chat-attach-input {
    visibility: hidden;
    opacity: 0;
    width: 0px;
  }
  .chat-attach-input-label {
    display: inline-flex;
    padding: 5px;
    cursor: pointer;
  }
  .chat-bottom-div {
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;

    .chat-input {
      background: #ffffff;
      box-sizing: border-box;
      width: 300px;

      input {
        padding: 16.5px 14px !important;
      }
      fieldset {
        border: 0px !important;
      }
    }
    .chat-send {
      height: 52px;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      border: 0px !important;
      color: #ffffff;
      border-radius: 0px;
      width: 65px;
      background-color: #e7792b;
    }
  }

  .chat-form-bottom-div {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .chat-send {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      border: none;
      color: #ffffff;
      border-radius: 5px;
      background-color: #e7792b;
      height: 48px;
      max-width: 250px;
      width: 100%;
    }
  }

  .chat-message {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-end;

    &.received {
      align-items: flex-start;
    }

    .details {
      min-width: 150px;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }
    .message-container {
      display: flex;
      padding: 10px;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      min-width: 150px;
      max-width: 80%;
      display: flex;
      box-sizing: border-box;

      &.received {
        justify-content: flex-start;
      }

      &.unread {
        background-color: #e7792b40 !important;
      }

      .userAvatar {
        background-color: #e7792b;
        cursor: default;
        margin-right: 10px;
        height: 30px !important;
        width: 30px !important;
        font-size: 16px;
        margin: 0px 0px 0px 10px;
        text-transform: uppercase;

        &.otherUser {
          background-color: #e7792b;
          font-size: 16px;
          margin-right: 10px;
          color: white;
          margin: 0px 10px 0px 0px;
        }
      }
    }

    .message {
      font-size: 14px;
    }
  }
}
.file-wrapper {
  display: flex;
  align-items: center;
}
.file-container {
  color: darkslategray;
  padding: 0 8px;
  display: inline-flex;

  svg {
    margin-right: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

.load-history-chats-link {
  position: absolute;
  font-size: 14px;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  text-align: center;
}
