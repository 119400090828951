.dashboard-container {
  max-width: 1110px;
  justify-content: center;
  margin: 40px auto 80px auto;

  .dashboard-mission-image {
    margin-top: 50px;
    height: fit-content;
    max-width: 550px;
  }


  .dashboard-fda-image {
    height: auto;
    max-width: 150px;
  }


  .digitalMeasurement {
    margin-top: 50px !important;
  }

  .content-wrapper {
    padding: 40px 40px 20px 0px;

    .title {
      color: #2f281e;
      font-family: Poppins;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 55px;
    }

    .subtitle {
      margin-top: 25px;
      color: #544837;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }

    .started-button {
      height: 53px;
      width: 175px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 50px 0px;
    }

    .demo-button {
      height: 53px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 50px 0px;
    }

    .app-link {
      height: 60px;
      width: 180px;
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      background-color: #000000;
    }

    .app-links-container {
      display: flex;
    }
  }
}

.iframe-video {
  margin-top: 40px;
  margin-bottom: 30px;
  height: 353px;
  width: 675px;
}

.iframe-video-top {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 353px;
  width: 675px;
}

.sponsors-wrapper {
  justify-content: center;
  flex-direction: row;
  background: linear-gradient(360deg,
      rgba(255, 229, 210, 0.63) 0%,
      rgba(255, 244, 229, 0) 100%);

  .title {
    width: 100%;
    text-align: center;
    color: #2f281e;
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 55px;
  }

  .sponsor-image-wrapper {
    max-width: 1110px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    img {
      max-height: 200px;
      max-width: 300px;
      margin-top: 75px;
    }

    .image-wrapper {
      min-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.workflow-dashboard-wrapper {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  padding-top: 80px;

  .title {
    color: #2f281e;
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 55px;
    text-align: center;
  }

  .subtitle {
    color: #a0988f;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  }

  .content-wrapper {
    padding: 30px 0px 30px 0px !important;
  }
}

.sponsors-gradient-container {
  width: 100%;
  text-align: center;
  margin-top: 95px;
  margin-bottom: 60px;

  button {
    height: 53px;
    width: 175px;
    border-radius: 4px;
    background-color: #e7792b;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.provider-schedule-demo {
  width: 100%;
  text-align: left;
  margin-top: 35px;
  margin-bottom: -45px;

  button {
    height: 53px;
    width: 375px;
    border-radius: 4px;
    background-color: #e7792b;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
  }
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
}

.review-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: auto;
}

/* Centered text */
.centered {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-icon {
  height: 30px;
  width: 30px;
  margin-top: 20px;
  margin-right: 20px;
}

.testimonial-wrapper {
  justify-content: center;
  margin-bottom: 130px;

  .title {
    color: #2f281e;
    font-family: Poppins;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 51px;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
  }

  .testimonial-content-wrapper {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;

    .image-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }

    .image-container {
      height: 62px;
      width: 62px;
      border: 1px solid #efeeee;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 30px;
    }

    .text-wrapper {
      color: #878da4;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      height: 146px;
      max-width: 246px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .slick-dots {
      bottom: -85px !important;

      li button:before {
        font-size: 20px !important;
        color: #e7792b !important;
      }
    }

    // .slick-active:nth-child(2) > div {
    //   margin-top: 60px;
    // }
    .slick-slide>div {
      margin: 30px 20px;
    }
  }

  .testimonial-content-div {
    box-sizing: border-box;
    padding: 0px 60px 5px 45px;
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
  }
}

.team-wrapper {
  justify-content: center;
  background: linear-gradient(0.38deg, rgba(255, 250, 247, 0) 0%, #fff4eb 100%);
  padding-top: 80px;

  .title {
    //width: 120%;
    text-align: center;
    color: #2f281e;
    font-family: Poppins;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 51px;
    margin-bottom: 65px;
  }

  .team-members-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .profile-img {
      height: 32px;
      width: 32px;
    }

    .member-container {
      min-height: 530px;
      max-width: 265px;
      margin-bottom: 60px;
    }

    .member-detail-wrapper {
      height: 125px;
    }

    img {
      height: 225px;
      width: 225px;
      border-radius: 50%;
    }

    .title {
      margin-top: 25px;
      margin-bottom: 20px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.45px;
      line-height: 15px;
      text-align: center;
    }

    .subtitle {
      margin-bottom: 10px;
      color: #e7792b;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.45px;
      line-height: 15px;
      text-align: center;
    }

    .text-wrapper {
      margin-top: 35px;
      color: #37324b;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.32px;
      line-height: 21px;
      text-align: center;
    }
  }
}

.customer-support {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;

  .userAvatar {
    height: 56px !important;
    width: 56px !important;
  }

  .MuiBadge-colorPrimary {
    background-color: #cf5f0f !important;
  }
}

.userAvatar {
  height: 44px !important;
  width: 44px !important;
  cursor: pointer;
}

.link-container {
  display: flex;
  justify-content: space-between;
}

.quick-links-container {
  max-width: 1110px;
  margin: 0 auto;
  padding: 55px 0px 100px 0px;
  border-top: 1px solid rgba(188, 208, 229, 0.5);

  .footer-logo {
    height: 37px;
    width: 133px;
  }

  .link-wrapper {
    padding: 0px 60px;

    .title {
      color: #2f281e;
      font-family: Gotham;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
    }

    .footer-link {
      color: #544837;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 35px;
    }
  }
}

.slick-arrow {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    margin: 40px auto 50px auto;

    .dashboard-mission-image {
      margin-top: 50px;
      height: fit-content;
      max-width: 550px;
    }

    .content-wrapper {
      text-align: center;
      padding: 20px;

      .started-button {
        height: 53px;
        width: 340px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0px 10px -15px;
      }

      .demo-button {
        height: 53px;
        width: 340px;
        border-radius: 4px;
        background-color: #e7792b;
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0px 40px -15px;
      }

      .app-link {
        height: 30px;
        width: 120px;
        border: 1px solid #a6a6a6;
        border-radius: 5px;
        background-color: #000000;
      }
    }

    .app-links-container {
      justify-content: center;
    }

  }

  .quick-links-container {
    .link-wrapper {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .first-container {
      padding-bottom: 5px !important;
    }
  }

  /* Container holding the image and the text */
  .container {
    position: relative;
    color: black;
    width: fit-content;
    font-size: small;
  }

  .review-image {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: auto;

  }

  .iframe-video {
    margin-top: 40px;
    margin-bottom: 30px;
    height: 175px;
    width: 355px;
  }

  .iframe-video-top {
    margin-top: 10px;
    margin-bottom: 20px;
    height: 175px;
    width: 355px;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    //left: 50%;
    text-align: justify;
    transform: translate(-50%, -50%);
    letter-spacing: 0.32px;
    margin-left: 0%;
    //margin-right: 20%;

  }

  .social-icon {
    height: 25px;
    width: 25px;
    margin-top: 20px;
    margin-right: 20px;
  }

}

@media only screen and (min-width: 486px) and (max-width: 768px) {
  .dashboard-container .dashboard-mission-image {
    height: auto;
    max-width: 380px;

  }

  .testimonial-content-wrapper {
    max-width: 700px !important;
  }

  .dashboard-container .dashboard-mission-image {
    height: auto;
    max-width: 380px !important;
  }

  .dashboard-container .content-wrapper {
    text-align: center;
    padding: 20px;

    .started-button {
      height: 53px;
      width: 340px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 10px -15px;
    }

    .demo-button {
      height: 53px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 40px -15px;
    }

    .app-link {
      height: 30px;
      width: 120px;
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      background-color: #000000;
    }
  }

  .workflow-image-overlap img {
    max-width: 350px;
  }

  .iframe-video {
    margin-top: 40px;
    margin-bottom: 30px;
    height: 175px;
    width: 355px;
  }

  .iframe-video-top {
    margin-top: 10px;
    margin-bottom: 20px;
    height: 175px;
    width: 355px;
  }

  /* Container holding the image and the text */
  .container {
    position: relative;
    color: black;
    width: fit-content;
    font-size: small;
  }

  .review-image {
    filter: blur(8px);
    -webkit-filter: blur(12px);
    max-width: 350px;
    height: auto;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    // left: 50%;
    text-align: justify;
    transform: translate(-50%, -50%);
    letter-spacing: 0.32px;
    margin-left: 0%;
    //margin-right: 20%;

  }

  .social-icon {
    height: 25px;
    width: 25px;
    margin-top: 20px;
    margin-right: 20px;
  }

}

@media only screen and (max-width: 486px) {
  .app-links-container img {
    max-width: 130px !important;
  }

  .workflow-image-overlap img {
    max-width: 275px !important;
  }

  .dashboard-container .dashboard-mission-image {
    height: auto;
    max-width: 300px !important;
  }

  .dashboard-container .content-wrapper {
    text-align: center;
    padding: 20px;

    .started-button {
      height: 53px;
      width: 340px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 10px -15px;
    }

    .demo-button {
      height: 53px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 40px -15px;
    }

    .app-link {
      height: 30px;
      width: 120px;
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      background-color: #000000;
    }
  }

  .workflow_tabs .MuiTab-root {
    max-width: 150px !important;
  }

  .workflow-image-overlap .text-content {
    bottom: 37% !important;
    left: 30% !important;
  }

  .workflow-image-text {
    bottom: 80px !important;
    right: 20px !important;
  }

  .workflow-circle {
    .workflow-container {
      flex-direction: column-reverse !important;
    }
  }

  .contactus-image {
    width: 350px !important;
  }

  .user-chat-container {
    width: 300px !important;

    .chat-bottom-div .chat-input {
      width: 200px !important;
    }
  }

  .iframe-video {
    margin-top: 40px;
    margin-bottom: 30px;
    height: 175px;
    width: 355px;
  }

  .iframe-video-top {
    margin-bottom: 20px;
    height: 175px;
    width: 355px;
  }

  /* Container holding the image and the text */
  .container {
    position: relative;
    text-align: justify;
    color: white;
    width: 400px !important;

  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    text-align: justify;
    transform: translate(-50%, -50%);
    letter-spacing: 0.32px;
  }

  .review-image {
    filter: blur(8px);
    -webkit-filter: blur(12px);
    max-width: 380px !important;
    height: auto;

  }

  .provider-schedule-demo {
    width: 100%;
    text-align: left;
    margin-top: 35px;
    margin-bottom: -45px;

    button {
      height: 53px;
      width: 315px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

}

@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .testimonial-content-wrapper {
    max-width: 1000px !important;
  }

  .dashboard-container .dashboard-mission-image {
    max-width: 400px !important;
    height: auto;
  }

  .dashboard-container .content-wrapper {
    text-align: center;
    padding: 20px;

    .started-button {
      height: 53px;
      width: 300px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 10px 0px;
    }

    .demo-button {
      height: 53px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px 40px 0px;
    }

    .app-link {
      height: 30px;
      width: 120px;
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      background-color: #000000;
    }
  }

  .workflow-image-overlap img {
    max-width: 400px;
  }

  /* Container holding the image and the text */
  .container {
    position: relative;
    color: black;
    width: fit-content;
    font-size: small;
  }

  .review-image {
    filter: blur(8px);
    -webkit-filter: blur(12px);
    max-width: 400px !important;
    height: auto;

  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    //left: 50%;
    text-align: justify;
    transform: translate(-50%, -50%);
    letter-spacing: 0.32px;
    margin-left: 0%;
    // margin-right: 20%;

  }

  .social-icon {
    height: 25px;
    width: 25px;
    margin-top: 20px;
    margin-right: 20px;
  }

  .provider-schedule-demo {
    width: 100%;
    text-align: left;
    margin-top: 35px;
    margin-bottom: -45px;

    button {
      height: 53px;
      width: 315px;
      border-radius: 4px;
      background-color: #e7792b;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
    }
  }
}

@media only screen and (max-width: 1110px) {

  .team-wrapper,
  .quick-links-container,
  .dashboard-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* Container holding the image and the text */
  .container {
    position: relative;
    color: black;
    width: fit-content;
    font-size: small;
  }

  .review-image {
    filter: blur(8px);
    -webkit-filter: blur(12px);
    height: auto;

  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    // left: 50%;
    text-align: justify;
    transform: translate(-50%, -50%);
    letter-spacing: 0.32px;
    margin-left: 0%;
    // margin-right: 20%;

  }

  .social-icon {
    height: 25px;
    width: 25px;
    margin-top: 20px;
    margin-right: 20px;
  }
}