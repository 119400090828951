.foo {
    counter-reset: foo;
}

.foo li {
    list-style-type: none;
}

.foo li::before {
    counter-increment: foo;
    content: "1." counter(foo) " ";
}