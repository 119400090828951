.contactus-wrapper {
  flex-direction: column;
  background: linear-gradient(0.38deg, rgba(255, 250, 247, 0) 0%, #fff4eb 100%);

  .heading {
    text-align: center;
    color: #e7792b;
    font-family: Poppins;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 51px;
    padding: 80px 0px;
  }

  .contact-us-button {
    height: 40px;
    width: 325px;
    border-radius: 4px;
    background-color: #e7792b;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }

  .contactus-form-field {
    margin-bottom: 20px;
  }

  .contactus-form {
    position: relative;
    max-width: 325px;
    margin: 0 auto;
  }

  .contactus-input {
    width: 325px;

    .MuiOutlinedInput-multiline {
      padding: 0px;
    }

    .MuiOutlinedInput-input {
      padding: 15.5px 14px !important;
    }

    fieldset {
      border: 1px solid #dddddd;
      border-radius: 4px;
    }

    input,
    textarea {
      background-color: #ffffff;
    }
  }

  .contactus-content-wrapper {
    padding-bottom: 85px;
  }

  .contactus-image {
    height: auto;
    width: 414px;
  }

  .contactus-form-wrapper {}

  .social-icon {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-right: 20px;
  }

  .social-block {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .contactus-content-wrapper {
    .MuiGrid-grid-sm-6 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}