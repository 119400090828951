.footer-wrapper {
  padding: 15px 0px;
  flex-direction: row;
  justify-content: center;
  display: flex;
  border: 1px solid rgba(188, 208, 229, 0.5);

  .footer-nav {
    display: inline-flex;
    a {
      color: #a0988f;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;

      &.active {
        color: #e7792b !important;
      }
    }
  }

  .copyright-text {
    color: #a0988f;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: right;
  }
}
