@import "~video-react/styles/scss/video-react";

body {
  position: relative;
}

body * {
  font-family: "Poppins", sans-serif !important;
}

* {
  outline: none;
}

/* ====================
 Help class start
 ==================== */
* {
  outline: none;
}
.mt80 {
  margin-top: 80px;
}
.mt40 {
  margin-top: 40px;
}

.mt30 {
  margin-top: 30px;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}
.mt4 {
  margin-top: 4px;
}
.mt3 {
  margin-top: 3px;
}
.mt2 {
  margin-top: 2px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml20 {
  margin-left: 20px;
}

.ml15 {
  margin-left: 15px;
}

.ml10 {
  margin-left: 10px;
}

.ml5 {
  margin-left: 5px;
}

.mr20 {
  margin-right: 20px;
}

.mr15 {
  margin-right: 15px;
}

.mr10 {
  margin-right: 10px;
}

.mr5 {
  margin-right: 5px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt20 {
  padding-top: 20px;
}

.pt15 {
  padding-top: 15px;
}

.pt10 {
  padding-top: 10px;
}

.pt5 {
  padding-top: 5px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl20 {
  padding-left: 20px;
}

.pl15 {
  padding-left: 15px;
}

.pl10 {
  padding-left: 10px;
}

.pl5 {
  padding-left: 5px;
}

.pl65 {
  padding-left: 65px !important;
}

.pr20 {
  padding-right: 20px;
}

.pr15 {
  padding-right: 15px;
}

.pr10 {
  padding-right: 10px;
}

.pr5 {
  padding-right: 5px;
}

.no_mar {
  margin: 0 !important;
}

.no_pad {
  padding: 0 !important;
}

.p_t_n {
  padding-top: 0 !important;
}

.p_b_n {
  padding-bottom: 0 !important;
}

.p_l_n {
  padding-left: 0 !important;
}

.p_r_n {
  padding-right: 0 !important;
}

.p30 {
  padding: 30px;
}

.no_float {
  float: none;
}

.d_b {
  display: block;
}

.inline_flex {
  display: inline-flex;
}
.d_c {
  display: contents !important;
}
.hide {
  display: none;
}
.show {
  display: block;
}

.breake_all {
  word-break: break-all;
}

.display-flex {
  display: flex;
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.error {
  border: 0px;
  color: red;
  font-size: 12px;
}

.success {
  border: 0px;
  color: #e7792b;
  font-size: 12px;
}

.width-100 {
  width: 100%;
}

.disable-link {
  opacity: 0.5 !important;
  cursor: default !important;
  text-decoration: none !important;
}

.continue_img {
  height: 15px;
  width: 15px;
  vertical-align: middle;
}

.reduce-opacity {
  opacity: 0.5 !important;
}

.cursor_pointer {
  cursor: pointer;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  &.login {
    background: #ffffff;
    border: 1px solid #bcbcbc;
    box-sizing: border-box;
    border-radius: 4px;
    height: 57px;
    max-width: 410px;
    width: 100%;
    padding-left: 10px;
  }
}

button {
  text-transform: capitalize !important;
  &:focus {
    outline: none !important;
  }
  &.signin {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    border: none;
    color: #ffffff;
    height: 48px;
    max-width: 294px;
    width: 100%;
    border-radius: 24px;
    background-color: #e7792b;
  }
}

.page-container {
  background-color: rgba(245, 246, 250, 0.432938);
}

.error-container {
  width: 400px;
  margin: 40px auto;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 16px;
  a {
    color: #ea994d;
  }
}

.body-wrapper {
  max-width: 1110px;
  margin: 0 auto;
  min-height: 100vh;
}
.content-wrapper {
  padding: 30px 0px 60px 0px;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header-logo {
  margin: 30px 0px 0px 30px;
}
.login-container {
  position: relative;
  margin: 12px auto;
  margin-bottom: 100px !important;
  background: #fff;
  height: 828px;
  max-width: 558px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 9px 20px 0 rgba(209, 208, 206, 0.2);

  .login {
    max-width: 410px;
    width: 100%;
  }

  .message-container {
    margin: 0 auto;
    padding-top: 20px;
    max-width: 410px;
    width: 100%;
    border: 0px;
    color: red;
    font-size: 12px;
    text-align: center;
  }

  input[type="text"],
  input[type="password"] {
    height: 20px;
  }
  fieldset {
    border: 1px solid #e8e2dd;
  }
  .login-button-container {
    text-align: center;
    margin-top: 36px;
  }
  .signup-option-container {
    margin-top: 80px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 16px;
    a {
      color: #ea994d;
    }
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e8e2dd !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #a0988f !important;
  }
  .MuiFormLabel-root {
    color: #a0988f !important;
  }
}

.login_form {
  margin-bottom: 55px !important;
  padding: 126px 0px 0px 71px;
  .welcome_text {
    font-size: 20px;
    color: #322e46;
  }
  .logo_text {
    font-size: 38px;
    color: #e7792b;
    line-height: 41px;
  }
}

.login_img {
  max-height: 500px;
  max-width: 600px;
  margin-top: 150px;
  display: inline-block;
  img {
    width: 100%;
  }
}

fieldset {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.backdrop-loader {
  position: absolute !important;
  z-index: 1 !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-backdrop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: inline-block;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #00a159;
  width: 75px;
  height: 75px;
  margin: 20px;
  animation: spin 2s linear infinite;
}

.pm-button {
  box-shadow: none !important;

  &:hover {
    background-color: #cf5f0f !important;
    color: #fff !important;
  }
}

.checkboxLabel {
  svg {
    height: 16px;
    width: 16px;
    color: #e8e2dd;
  }

  .Mui-checked {
    svg {
      fill: #e7792b !important;
    }
  }
}

.forgot-container {
  margin-top: 8px;

  .MuiFormControlLabel-label {
    color: #46372e;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
  }
  svg {
    height: 14px;
    width: 14px;
  }
  .rememberMe {
    &.Mui-checked {
      color: #e7792b;
    }
  }
  .forgot-div a {
    color: #e7792b;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
  }
  .remember-div {
    display: inline-block;
    text-align: left;
    width: 205px;
  }
  .forgot-div {
    display: inline-block;
    width: 205px;
    text-align: right;
  }
}

.social-signin-container {
  display: none;
  margin-top: 23px;
  text-align: center;
  .google-login {
    box-sizing: border-box;
    height: 41px;
    width: 109px;
    border: 1px solid rgba(119, 134, 158, 0.3);
    border-radius: 20px;
    background-color: #ffffff;
  }
  .facebook-login {
    box-sizing: border-box;
    height: 41px;
    width: 109px;
    border: 1px solid rgba(119, 134, 158, 0.3);
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 25px;
  }
}

.info-container {
  position: relative;
  margin: 12px auto;
  margin-bottom: 100px !important;
  background: #fff;
  height: 828px;
  max-width: 558px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 9px 20px 0 rgba(209, 208, 206, 0.2);

  &.add-height {
    height: 1020px;
  }

  .Mui-selected {
    color: #e7792b !important;
  }

  .child-info-tabs {
    min-height: 13px !important;
  }

  .tab-button {
    min-height: 13px !important;
    border-top-left-radius: 8px;

    &.submitted_25 {
      width: 25%;
      background-color: #e7792b !important;
    }
    &.submitted_50 {
      width: 50%;
      background-color: #e7792b !important;
    }
    &.submitted_75 {
      width: 75%;
      background-color: #e7792b !important;
    }
    &.submitted_100 {
      width: 100%;
      background-color: #e7792b !important;
      border-top-right-radius: 8px;
    }
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8e2dd !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e8e2dd !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #a0988f !important;
  }
}

.signup-text {
  padding-top: 54px;
  padding-left: 50px;

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 41px;
    letter-spacing: 0.178181px;
    color: #a0988f;
  }
  .title {
    color: #46372e;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 41px;
  }
}

.signup-confirmation-text {
  padding-top: 54px;
  text-align: center;

  .subtitle {
    margin: 0px 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.178181px;
    color: #a0988f;
  }
  .title {
    color: #e7792b;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 41px;
  }
}

.relation-dropdown-item {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar,
  .MuiPickersDay-daySelected {
    background-color: #e7792b !important;
  }
  .MuiPickersYear-yearSelected,
  .MuiTypography-colorPrimary {
    color: #e7792b !important;
  }
}

.signup-container {
  margin: 0px 50px;

  .text-heading {
    margin-top: 40px;
    margin-bottom: 10px;
    color: #46372e;
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 41px;
  }
  .signUpOption {
    height: 48px;
    width: 30%;
    border-radius: 24px;
    background-color: #f7f6f6;
    color: #a0988f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;

    &.selected {
      border-radius: 24px;
      background-color: #e7792b;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;
    }
  }
  .signUpOption:nth-of-type(2) {
    margin: 0px 20px;
  }
  .signupParent {
    width: 100%;
    margin-bottom: 20px;

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #e8e2dd;
    }
    .MuiFormLabel-root {
      color: #a0988f !important;
    }
  }

  .sublabel-terms,
  .sublabel-conditon-link {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .sublabel-conditon-link {
    color: #e7792b;
    font-weight: 600 !important;

    a {
      color: #e7792b;
      font-weight: 600 !important;
    }
  }
  .acceptHipaaGuideline {
    height: 16px;
    width: 16px;
    padding: 0px 8px;
  }
  .relation-dropdown {
    max-width: 180px;
    width: 50%;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 60px;
  }
  .MuiSelect-root {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .child-details {
    margin-top: 60px;
  }
  .relationship-title {
    opacity: 0.43;
    color: #46372e;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.18px;
    line-height: 41px;
  }
}

.gender-container,
.preterm-container,
.race-container,
.conditions-container {
  margin-top: 54px;
  margin-left: 50px;
  margin-right: 25px;

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.178181px;
    color: #a0988f;
  }
  .title {
    color: #46372e;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 41px;
    width: 50%;
    display: inline-block;
  }
  .skipOption {
    width: 50%;
    text-align: right;
    display: inline-block;
    letter-spacing: 0;
    line-height: 19px;
    vertical-align: top;
  }
  .skipLink {
    color: #b2b1b5;
    font-size: 16px;
  }
}

.mobile-links {
  background-color: yellow;
  padding: 10px;
  text-align: center;

  a {
    font-weight: 600;
    color: #ea994d;
  }
}

.disclaimer-content-ul {
  list-style: decimal;

  li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 1110px) {
  .header-wrapper,
  .text-content-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
