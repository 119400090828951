.helpcenter-text {
    margin-bottom: 30px;

    .title {
        color: #322e46;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 5px;
    }

    .sub-title {
        color: #aeb2b8;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.33px;
        line-height: 18px;
    }
}

.helpcenter-wrapper {
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
}

.some-link {
    text-decoration: underline !important;
    color: #449fff;

    &.orange {
        color: #e7792b;
    }
}

.help-center-table {
    width: 100%;
    border: 1px solid #dedede;
    border-collapse: collapse;

    &.fix-width-table {
        width: 625px;
        margin: 30px auto;
    }

    td,
    th {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        border: 1px solid #dedede;
    }

    th {
        color: #46372e;
    }

    .image-wrapper {
        width: 50%;
        height: 50%;
    }

    tr td:first-child {
        text-align: left !important;
        color: #46372e !important;
        font-weight: 600;
    }
}

.helpcenter-parallel-div {
    display: flex;

    .second-div {
        padding-left: 30px;
        border-left: 3px solid #a0988f;
        margin-left: 30px;
    }
}

.help-center-note {
    font-size: 12px;
}

.helpcenter-accordion {
    padding: 5px 40px 15px 30px;
    box-shadow: none !important;
    margin-bottom: 0px;
    border-bottom: 1px solid #ededed;
    position: unset !important;

    &.Mui-expanded {
        border-bottom: 1px solid #ededed !important;
        margin: 0px !important;
    }

    .img-container {
        height: 150px;
        width: 130px;
        margin: 0px 30px;
        text-align: center;

        img {
            height: 100%;
            width: 100%;

            &.fix_width {
                width: 110px;
            }
        }
    }

    .helpcenter-accordion-title {
        color: #46372e;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.44px;
        line-height: 25px;
    }

    .helpcenter-accordion-details {
        color: #a0988f;
        font-size: 16px;
        letter-spacing: 0.44px;
        line-height: 25px;
        width: 100%;

        li {
            color: #46372e;

            span {
                color: #a0988f;
            }

            b {
                color: #46372e;
            }
        }

        &.product-info {
            color: #46372e;

            .img-container {
                text-align: center;
                margin: 0px 50px;
            }

            .some-link {
                color: #e7792b;
                text-decoration: underline;
                font-weight: 600;
            }
        }

        .content-wrapper {
            width: calc(100% - 230px);
        }

        .MuiTabs-flexContainer {
            justify-content: center;
        }

        .MuiTab-root {
            color: #a0988f;
            font-family: Poppins;
            font-size: 12.8px;
            letter-spacing: 0.24px;
            line-height: 18px;
            height: 50px;
            max-width: 160px;

            &.Mui-selected {
                background-color: #e7792b !important;
                color: white !important;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding-top: 0px !important;
    }

    .MuiAccordionSummary-content {
        margin: 20px 0px 15px 0px !important;
    }
}

.text-img-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .text-title {
        color: #322e46;
        font-weight: 500;
    }
}

.text-wrapper {
    margin-top: 80px;
}

.table-cell-details {
    color: #a0988f;
    font-size: 14px;
    width: 100%;
}

.workflow-image-overlap {
    position: relative;

    .text-content {
        color: #e87a2c;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        bottom: 40%;
        left: 38%;
        background-color: white;
    }
}

@media only screen and (max-width: 486px) {

    .text-img-wrapper {
        display: flex;
        align-items: left;
        margin-top: 30px;
        justify-content: center;

        .text-title {
            color: #322e46;
            font-weight: 500;
        }
    }

    .helpcenter-text {
        margin-bottom: 30px;
        margin-left: 5%;

        .title {
            color: #322e46;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            margin-bottom: 5px;
        }

        .sub-title {
            color: #aeb2b8;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.33px;
            line-height: 18px;
        }
    }

    .help-center-table {
        width: 100%;
        border: 1px solid #dedede;
        border-collapse: collapse;

        &.fix-width-table {
            width: 325px;
            margin: 30px auto;
        }

        td,
        th {
            padding: 10px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #dedede;
        }

        th {
            color: #46372e;
        }

        .image-wrapper {
            width: 100px;
            height: auto;
        }

        tr td:first-child {
            text-align: left !important;
            color: #46372e !important;
            font-weight: 600;
        }
    }

    .image-wrapper {
        width: 300px;
        height: auto;
    }
}